import React from 'react';

function Contribute(props) {

  return (
    <div className="container">
      <div className="page">
        <div>
          <h1>Contribute</h1>
          <h2>What can you do to bring a broader and more equal set of voices 'on stage' and 'on paper'?</h2>
          <h3>Great question. Here are a few ideas:</h3>
        </div>
        <div>
          <h2 className="italic">Action #1: Use a Quote in your Presentation or Article</h2>
          <h3>When featuring a quote from What Women Say in your lecture, workshop, presentation, 
          article or blog post, remember to mention us.</h3>
          <p>Image that you present to an audience of 100 people. That means that 100 people will hear about What Women Say 
          and start using our library for their own presentations. 
          This helps us spread the message and bring more women on stage.</p>
          <h3>What Women Say Logos & Media Kit</h3>
          <div className="button-wrapper">
            <a className="button" rel="noopener noreferrer" target="_blank" href="//bit.ly/WWSmediakit">Download</a>
          </div>
        </div>
        <div>
          <h2>Action #2: Start Tweeting & Posting about it</h2>
          <h3>When sharing relevant articles, infographics, and pictures add the hashtag #whatwomensay to your tweets or posts.</h3>
          <p>There’s a tremendous amount of inertia that keeps people on the safe side. 
          Nothing against Albert Einstein or Steve Jobs but, we think it’s time is bring relatable examples on stage. 
          To change this trend, we need to drastically change the narrative.</p>
          <p>Share the movement with your people <strong>#whatwomensay</strong></p>
        </div>
        <div>
          <h2>Action #3: Submit your Quote</h2>
          <h3>What Women Say belong to all of us.</h3>
          <p>We strive to bring a broader and more equal set of voices 'on stage' and 'on paper' by growing our library.</p>
          <p>If there’s an extraordinary woman we should be aware of (there sure is!) and one or more quotes we should 
          add to What Women Say, we’d love to know it.</p>
          <p>Email to submit a quote.</p>
          <div className="button-wrapper">
            <a className="button" rel="noopener noreferrer" href="mailto:whatwomensayquotes@gmail.com">Email us</a>
          </div>
        </div>
        <div style={{paddingBottom: "200px"}}>
          <h2>Action #4: Consider a donation</h2>
          <h3>Are you a frequent user of our platform? Do we help you save time and energy in always getting unique and relevant quotes? Do you find value in What Women Say?</h3>
          <p>Help us help you by giving us a donation. <span role="img" aria-label="smile">😉</span> Whatever sum you give us helps us keep this platform up and running, and cover all operational costs. Thank you so much!</p>
          <div className="button-wrapper">
            <a className="bmc-button" target="_blank" rel="noopener noreferrer" href="https://www.buymeacoffee.com/whatwomensay">
              <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy me a coffee" />
              <span>Buy me a coffee</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contribute;