import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import logo from '../img/What-Women-Say.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Header extends Component {

  constructor() {
    super();

    this.state = { width: "0" };

    this.openSideBar = this.openSideBar.bind(this);
    this.closeSideBar = this.closeSideBar.bind(this);
  }

  openSideBar = () => {
    this.setState({
      width: "250px"
    });
  }

  closeSideBar = () => {
    this.setState({
      width: 0
    });
  }

  render() {
    return (
      <div className="head">
  	    <div className="container">
          <div className="row">
      			<header className="header">
              {this.state.width !== "250px" ? 
              <FontAwesomeIcon
                icon={['fas', 'bars']}
                size="1x"
                onClick={this.openSideBar}
                className="open nav-toggler"
              /> :
              <FontAwesomeIcon
                icon={['fas', 'times']}
                size="1x"
                className="close nav-toggler"
                onClick={this.closeSideBar}
              />
              }
              <div className="sidenav" style={{width: this.state.width}}>
                <NavLink onClick={this.closeSideBar} to="/">Home</NavLink>
                <NavLink onClick={this.closeSideBar} to="/about">About</NavLink>
                <NavLink onClick={this.closeSideBar} to="/contribute">Contribute</NavLink>
                <a rel="noopener noreferrer" target="_blank" onClick={this.closeSideBar} href="//veronicafossa.typeform.com/to/o79vCOYh">Submit</a>
                <a rel="noopener noreferrer" onClick={this.closeSideBar} href="mailto:whatwomensayquotes@gmail.com">Contact</a>
              </div>
            </header>
  	     </div>
        </div>
        <header className="container masthead text-center">
          <div className="row">
            <Link to="/"><img src={logo} alt="Logo" /></Link>
          </div>
        </header>
      </div>
    );
  }

}

export default Header;