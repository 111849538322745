import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Components/Loader';
import GlobalVar from '../GlobalVar';

function Homepage(props) {

  const [quotes, setQuotes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCategories();
  },[]);

  useEffect(() => {
    fetchQuotes();
  }, [categories]);

  const allInfo = () => {
    fetchQuotes();
  }

  const fetchCategories = () => {
    axios.get(GlobalVar.serverUrl+'categories')
    .then(res => {
      const data = res.data.categories;
      if(typeof(data) != "undefined" && data !== null){
        let updatedCategories = [];
        let newColorsSet = GlobalVar.colorsSet.slice();
        for (var key in data){
          let catItem = '';
          let color = '';
          for(var i in newColorsSet) {
            if(newColorsSet[i].category === data[key]){
              color = newColorsSet[i].color;
            }
          }
          catItem = {
            key: key,
            category: data[key],
            color: color
          };
          updatedCategories.push(catItem);
        }
        setCategories(updatedCategories);
      } else {
        setCategories("No categories!");
      }
    });
  }

  const fetchQuotes = () => {
    axios.get(GlobalVar.serverUrl+'quotes')
      .then(res => {
        const data = res.data;
        if(typeof(data) != "undefined" && data !== null){
          let updatedQuotes = [];
          let color = '';
          for (var key in data){
            let quoteItem = '';
            for(var i in GlobalVar.colorsSet) {
              if(GlobalVar.colorsSet[i].category === data[key].category){
                color = GlobalVar.colorsSet[i].color;
              }
            }
            quoteItem = {
              key: key,
              id: data[key].id,
              quote: data[key].quote,
              quoteSub: data[key].quoteSub,
              author: data[key].author,
              category: data[key].category,
              color: color
            };
            updatedQuotes.push(quoteItem);
          }
          setQuotes(updatedQuotes);
          setLoading(false);
        } else {
          setQuotes("No quotes!");
          setLoading(false);
        }
    });
  }

  const getQuotesFromCat = (cat) => {
    axios.get(GlobalVar.serverUrl+'quotes/' + cat)
      .then(res => {
        const data = res.data;
        if(typeof(data) != "undefined" && data !== null){
          let updatedQuotes = [];
          let color = '';
          for (var key in data){
            let quoteItem = '';
            for(var i in categories) {
              if(categories[i].category === data[key].category){
                color = categories[i].color;
              }
            }
            quoteItem = {
              key: key,
              id: data[key].id,
              quote: data[key].quote,
              quoteSub: data[key].quoteSub,
              author: data[key].author,
              category: data[key].category,
              color: color
            };
            updatedQuotes.push(quoteItem);
          }
          setQuotes(updatedQuotes);
          setLoading(false);
        } else {
          setQuotes("No quotes!");
          setLoading(false);
        }
    });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="subtitle">
          <h2>What Women Say is the go-to resource of quotes by extraordinary females that provides you with a broader set of voices on your stage, paper, and screen.</h2>
        </div>
        <div className="masthead d-flex">
          <div 
            className="container m-auto" 
            style={{ position: 'relative' }}>
            <div className="row">
              <div className="category-nav-wrapper">
                <div className="category-nav">
                  <div className="category-item" key={1000}>
                    <button 
                      className="btn btn-lg"
                      style={{backgroundColor: '#C68BA7', color: '#fff'}}
                      onClick={() => allInfo()}>
                        All
                      </button>
                  </div>
                  {categories.map((category) =>
                    <div className="category-item" key={category.key}>
                      <button 
                        className="btn btn-lg" 
                        onClick={() => getQuotesFromCat(category.category)}
                        style={{ backgroundColor: category.color, color: '#fff' }}>
                          {category.category}
                        </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {loading ? <Loader loading={loading}/> :
              <div className="card-wrapper">
                {quotes.map((quote) =>
                  <div 
                    className="card"
                    key={quote.key}
                    style={{ backgroundColor: quote.color, color: '#fff' }}>
                    <Link to={`quote/${quote.id}`} >
                      <div className="card-body">
                        <h4 id="category">{quote.category}</h4>
                        <h3 id="quote">{quote.quoteSub}</h3>
                        <h4 className="text-right" id="author">
                          {quote.author}
                        </h4>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
          }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;