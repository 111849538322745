import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Loader from '../Components/Loader';
import {Animated} from "react-animated-css";
import GlobalVar from '../GlobalVar';

function SinglePoll() {

  // serverUrl = process.env.NODE_ENV === 'production' ? "https://what-women-say-server-n44juj6mt.now.sh/api/" : "http://localhost:5000/api/";
  const serverUrl =  "https://what-women-say-server-n44juj6mt.now.sh/api/"; 

  const [quote, setQuote] = useState("");
  const [author, setAuthor] = useState("");
  const [authorWiki, setAuthorWiki] = useState("");
  const [color, setColor] = useState("");
  const [category, setCategory] = useState("");
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  const { quoteId } = useParams();

  useEffect(() => {
    getOneQuote(quoteId);
  });

  const getOneQuote = (quoteId) => {
    axios.get(serverUrl+'quote/' + quoteId)
    .then(res => {
      const data = res.data;
      if(typeof(data) != "undefined" && data !== null){
        let newColorsSet = GlobalVar.colorsSet.slice();
        let cardColor = '';
        for(var i in newColorsSet) {
          if(newColorsSet[i].category === data.category){
            cardColor = newColorsSet[i].color;
          }
        }
        setQuote(data.quote);
        setAuthor(data.author);
        setAuthorWiki(data.authorWiki);
        setCategory(data.category);
        setColor(cardColor);
        setLoading(false);

      } else {
        setQuote("");
        setAuthor("");
        setAuthorWiki("");
        setCategory("");
        setLoading(false);
      }
    });
  }

  return (
    <div className="single">
     {!loading ? 
      <div className="go-back-container">
        <Link className="go-back-link" to="/">
          <FontAwesomeIcon
            icon={['fas', 'chevron-left']}
            id="go-back"
            size="1x"
          />
        </Link>
      </div>
      : null}
      <div 
        className="card"
        style={ {color: '#fff', backgroundColor: loading ? '#FBFAFA' : color, paddingLeft: '30px'} }>
        {loading ? <Loader loading={loading}/> :
          <div className="card-body">
            <div>
              <h4 id="category">{category}</h4>
              <h3 id="quote">{quote}</h3>
              {authorWiki === '' &&
                <h4 id="author">
                  {author}
                </h4>
              }
              {authorWiki !== '' &&
                <a 
                  href={authorWiki}
                  target="_blank"
                  rel="noopener noreferrer"
                  id="author-link"
                >
                  <h4 id="author"
                  style={ {color: '#fff'} }>
                    {author}
                  </h4>     
                </a>
              }      
              <a 
                href={`https://twitter.com/intent/tweet?hashtags=${(author).replace(/\s/g,'')},${category.replace(/-/g, "")},Quotes,WhatWomenSay&text=${quote}${' '}${window.location.href}`}
                target="_blank"
                rel="noopener noreferrer"
                className="twitter"
                data-tip="Share"
                style={ {color: '#fff'} }
              >
                <FontAwesomeIcon
                  icon={['fab', 'twitter']}
                size="1x"
                />
              </a>
              <div 
                style={ {cursor: 'pointer'} }
                id="copy-wrapper"
              >
                <CopyToClipboard text={quote}
                  onCopy={() => setCopied(true)}>
                  <FontAwesomeIcon
                    icon={['fas', 'copy']}
                    id="copy-icon"
                    size="1x"
                  />
                </CopyToClipboard>
                {' '}
                {copied ? 
                  <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={800} animationOutDuration={400} isVisible={copied}>
                  <span className="copied" style={{color: 'white'}}>Copied</span></Animated> : null}
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default SinglePoll;