import React from 'react';

function NoMatch(props) {
  
    return (
    	<div className="container">
        	<div className="page 404-page">
        		<h1 className="not-found has-text-centered">404</h1>
        		<h1 className="title-not-found has-text-centered">Not Found</h1>
        		<div className="button-wrapper"><a className="button" href="/">Back to Homepage</a></div>
        	</div>
    	</div>
    );
}

export default NoMatch;
