import React from 'react';

const Footer = () =>
	(<footer className="footer text-center">
    <p>
      <a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/">
        <img alt="Creative Commons License" style={{borderWidth: "0"}} src="https://i.creativecommons.org/l/by-sa/4.0/88x31.png" />
      </a>
      <br />
      This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/">
        Creative Commons Attribution-ShareAlike 4.0 International License
      </a> {' '}
      | Copyright {new Date().getFullYear()}. 
      Project by <a 
        href="//damianofossa.com"
        target="_blank"
        rel="noopener noreferrer"><span className="custom-link">Damiano Fossa</span>
      	</a> and <a 
	        href="//www.linkedin.com/in/veronicafossa/"
	        target="_blank"
	        rel="noopener noreferrer">
        	<span className="custom-link">Veronica Fossa</span>
        </a> 
    </p>
	</footer>);

export default Footer;