import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function About(props) {

  return (
    <div className="container">
      <div className="page">
        <div>
          <h1>About</h1>
          <p>The idea for What Women Say came to life in 2018 when Veronica Fossa attended a conference 
          for female entrepreneurs. A friend had convinced her to go to listen to the keynote speaker, 
          an internationally acclaimed creative professional. When he took the stage, everyone—an audience 
          of mostly women—was in awe of him. He ended his speech with a motivational video, featuring empowering 
          quotes by ten leading personalities who had overcome adversities to achieve great things. 
          Albert Einstein, Steve Jobs, Winston Churchill. 
          Out of ten personalities, only one was a woman: Oprah Winfrey.</p>
          <p>As he wrapped up his talk and received the round of applause, Veronica was disappointed. 
          If the purpose of the video was to empower the public of female entrepreneurs, it totally missed the point. 
          The hidden message was that  only one woman out of ten people can make outstanding things. 
          She didn’t relate to any of the role models he showed, except for Oprah.</p>
          <p>That video triggered something in her. She was sick of the same old quotes that didn’t resonate with 
          her as an ambitious woman and showed a lack of imagination. She shared her frustration with Damiano, her brother, 
          who is a web developer. The two started talking about collecting quotes by extraordinary women and creating a public 
          online library to collect relevant quotes in one place. What Women Say was born.</p>
        </div>
        <div className="img-section">
          <div className="img-container veronica"></div>
          <div className="img-container damiano"></div>
        </div>
        <div>
          <h2>What Women Say Logos & Media Kit</h2>
          <p>If you’d like to feature What Women Say somewhere, here is a ZIP files you can use with logos, 
          badges, images and sample texts.</p>
          <div className="button-wrapper">
            <a className="button" rel="noopener noreferrer" target="_blank" href="//bit.ly/WWSmediakit">Download</a>
          </div>
        </div>
        <div style={{paddingBottom: "60px"}}>
          <h2>Contact</h2>
          <p>At the moment, What Women Say is completely a not-for-profit side passion project for us. 
          We do our best to upload new quotes regularly but we need your help to keep on going and spread the message. 
          If you want to help, please read the page <Link className="simple-link" to="/support"><span className="custom-link">Support</span></Link>.</p>
          <p>If you have any suggestions for us or want to say hi, 
          please use <a className="simple-link" rel="noopener noreferrer" target="_blank" href="mailto:whatwomensayquotes@gmail.com"><span className="custom-link">this</span></a> email address</p>
          <p>
            Any kind of support is much appreciated. {' '}
            <FontAwesomeIcon
              icon={['fas', 'heart']}
              size="1x"
            />
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;