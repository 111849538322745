import React from 'react';
import ReactLoading from 'react-loading';

const Loading = ({loading}) => {
    if (!loading) return null;

    return (
        <div style={_style.main}>
            <ReactLoading type={'bubbles'} delay={1} color={'#014164'} height={60} width={60} />
        </div>
    );
};

const _style = {
    main: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99999,
    },
};

export default Loading;