
class GlobalVar{
  constructor() {
    // serverUrl = process.env.NODE_ENV === 'production' ? 
    // "https://what-women-say-server-n44juj6mt.now.sh/api/" : 
    // "http://localhost:5000/api/";

    this.serverUrl =  "https://what-women-say-server-kovh-1bz8gv3a3-damiano-fossas-projects.vercel.app/api/";
    this.colorsSet = [{category: 'Activism', color: '#4492A6'},
      {category: 'Education', color: '#ff719d'},
      {category: 'Self-knowledge', color: '#9E98C4'},
      {category: 'Motivation', color: '#f0bb13'},
      {category: 'Work', color: '#014e77'},
      {category: 'Sociability', color: '#52BD85'},
      {category: 'Feminism', color: '#F09D73'},
      {category: 'Creativity', color: '#87a7b8'},
      {category: 'Relationships', color: '#d4434e'},
      {category: 'Inspiration', color: '#BFA6A7'}];
  }

  checkIfObjectIsInLocalStorageArray(arrayName, objectToFind){
    if(localStorage.getItem(arrayName) !== null){
      const arrayToSearch = JSON.parse(localStorage.getItem(arrayName));
      if(arrayToSearch.indexOf(objectToFind) !== -1){
        return true;
      }
    }
    return false;
  }

  addToLocalStorageArray(arrayName, objectToAdd){
    let newArray = [objectToAdd];
    if(localStorage.getItem(arrayName) !== null){
      newArray = JSON.parse(localStorage.getItem(arrayName));
      newArray.push(objectToAdd);
    }
    localStorage.setItem(arrayName, JSON.stringify(newArray));
  }

  removeLocalStorageFromArray(arrayName, objectToRemove){
    let newArray = JSON.parse(localStorage.getItem(arrayName));
    newArray.splice(newArray.indexOf(objectToRemove), 1);
    localStorage.setItem(arrayName, JSON.stringify(newArray));
  }
}

export default (new GlobalVar());