import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { faCopy, faHeart, faBars, faTimes, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Homepage from './Pages/Homepage';
import About from './Pages/About';
import Contribute from './Pages/Contribute';
import SingleQuote from './Pages/SingleQuote';
import NoMatch from './Pages/NoMatch';
import Header from './Components/Header';
import Footer from './Components/Footer';

library.add(faTwitter, faTwitterSquare, faCopy, faHeart, faBars, faTimes, faChevronLeft);

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    demoAsyncCall().then(() => {
      setLoading(false);
    });
  });

  const demoAsyncCall = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 1500));
  }

    
  if(loading) { // if your component doesn't have to wait for an async action, remove this block 
    return null; // render null when app is not ready
  } else {
    return (
      <Router>
        <div>
          <Header />
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/contribute" element={<Contribute />} />
            <Route path="/quote/:quoteId" element={<SingleQuote />} />
            <Route path="/" element={<Homepage />} />
            <Route element={<NoMatch />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;